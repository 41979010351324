/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-15",
    versionChannel: "nightly",
    buildDate: "2023-09-15T00:24:47.575Z",
    commitHash: "7f416f85a8dd57337c3f7aa43c61b5566c7a6525",
};
